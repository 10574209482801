<template>
  <div class="password-change">
    <el-form :model="passwordModel"
      ref="myForm"
      status-icon
      :rules="rules"
      label-width="100px">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input type="password" v-model="passwordModel.oldPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="passwordModel.newPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="rePassword">
        <el-input type="password" v-model="passwordModel.rePassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleFormSubmit">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import auth from '@/common/auth'
import userService from '@/services/userService'

export default {
  name: 'PasswordChange',
  components: {
  },
  data () {
    return {
      passwordModel: {
        userGuid: auth.getUserInfo().userGuid,
        oldPassword: '',
        newPassword: '',
        rePassword: ''
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: '请填写原密码',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            message: '请填写新密码',
            trigger: 'blur'
          }
        ],
        rePassword: [
          {
            validator: this.passwordValidator,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    clearValidate () {
      this.$refs.myForm.clearValidate()
    },
    handleFormSubmit () {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          userService.changePassword(this.passwordModel.userGuid, auth.getUserInfo().userName, this.passwordModel.newPassword, this.passwordModel.oldPassword)
            .then((res) => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '修改成功'
                })
                this.$alert('3秒后将重新登录！')
                setTimeout(() => {
                  auth.logout()
                  window.location.reload()
                }, 3000)
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '修改失败！'
              })
            })
        } else {
          this.$message({
            type: 'warning',
            message: '请检查您的输入'
          })
        }
      })
    },
    passwordValidator (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordModel.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
  },
  created () {
  }
}
</script>
